import { Dialog, Box, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactRow } from "../../../../data/Contact/models";
import { newDemandWithTypeFields } from "../../../../data/Incident/forms/dialog";
import {
  IncidentParentRow,
  IncidentPost,
} from "../../../../data/Incident/models";
import { createIncident } from "../../../../data/Incident/queries";
import { ServiceRow } from "../../../../data/Service/models";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import useUser from "../../../../hooks/useUserData";
import { RouterRoutes } from "../../../../routes/routes";
import { QueryError } from "../../../../types";
import Transition from "../../../common/animation/DialogTransition";
import SimpleFormWithSectionsWithState from "../../../form/SimpleForm/SimpleFormWithSectionsWithState";
import { buildPartialFormObj } from "../../../form/SimpleForm/buildForm";
import { ProgramRow } from "../../../../data/Program/models";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  demand: IncidentParentRow;
  contacts: ContactRow[];
  programs: ProgramRow[];
};

const NewDemandDialog = ({
  open,
  setOpen,
  demand,
  contacts,
  programs,
}: Props) => {
  const { userData } = useUser();
  const queryClient = useQueryClient();

  const [fields, setFields] = useState(
    buildPartialFormObj(newDemandWithTypeFields, {
      vdl_contactprincipal: {
        value: userData.contactid,
        choices: contacts
          .filter((e) => e.statecode === 0)
          .map((contact) => {
            return {
              label: `${contact.firstname} ${contact.lastname}${
                contact.contactid === userData.contactid ? " (vous)" : ""
              }`,
              value: contact.contactid,
            };
          }),
      },
      vdl_typededemande: {
        value: null,
        choices: programs.flatMap((program) =>
          program.services.map((service) => ({
            value: service.vdl_typedeserviceid,
            label: service.vdl_name,
          }))
        ),
      },
    })
  );

  const mutation = useMutation<string | undefined, QueryError, IncidentPost>({
    mutationFn: (incident) => createIncident(incident),
    onSuccess: (data?: string) => {
      if (data) {
        showSnackbar("Demande créée", "success");
        queryClient.invalidateQueries({
          queryKey: ["Demand", demand.incidentid],
        });
      }
      setOpen(false);
    },

    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la création de la demande.",
        "error",
        error
      );
      setOpen(false);
    },
  });
  const { showSnackbar, showErrorSnackbar } = useSnackbar();

  async function handleSubmit() {
    
    if (!fields.vdl_contactprincipal.value || !fields.vdl_typededemande.value)
      return;
    mutation.mutate({
      title: fields.title.value,
      _vdl_contactprincipal_value: fields.vdl_contactprincipal.value,
      _vdl_typededemande_value: fields.vdl_typededemande.value,
      _parentcaseid_value: demand.incidentid
    });
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      fullWidth
    >
      <Box padding="16px 16px 0 16px">
        <Typography variant="h5" color="primary">
          Créer une sous-demande
        </Typography>
        <Typography>
          Après l'initiation de la demande, vous serez redirigé vers la page de
          détails de la demande pour compléter ses informations spécifiques.
        </Typography>
      </Box>
      <SimpleFormWithSectionsWithState
        fields={fields}
        setFields={setFields}
        isFormLoading={mutation.isPending}
        columns={{ minWidth: 220, count: 1 }}
        submitText={"Initier la création"}
        handleSuccess={handleSubmit}
        cancelText="Annuler"
        handleCancel={() => setOpen(false)}
      />
    </Dialog>
  );
};

export default NewDemandDialog;
