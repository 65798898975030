import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../common/buttons/CustomButton";
import {
  IncidentFilmingRow,
  IncidentSpecialEventRow,
} from "../../../../data/Incident/models";
import { ActivityRow } from "../../../../data/Activity/models";
import { QueryError } from "../../../../types";
import { useQuery } from "@tanstack/react-query";
import { getActivities } from "../../../../data/Activity/queries";
import { getActivityTypes } from "../../../../data/ActivityType/queries";
import { ActivityTypes } from "../../../../data/ActivityType/models";
import ActivityCard from "./ActivityCard";
import QueryErrorMessage from "../../../common/error/QueryErrorMessage";
import {
  canUserEditIncident,
  isIncidentEditable,
} from "../../../../data/Incident/rules";
import useUser from "../../../../hooks/useUserData";
import NewActivityDialog from "./NewActivityDialog";
import ActivityCards from "./ActivityCards";
import NewActivityButton from "./NewActivityButton";

type Props = {
  demand: IncidentSpecialEventRow | IncidentFilmingRow;
};

const ActivitiesTab = ({ demand }: Props) => {
  const { userData } = useUser();

  const activitiesQuery = useQuery<ActivityRow[], QueryError>({
    queryKey: ["Activities", demand.incidentid],
    queryFn: () => getActivities(demand.incidentid),
  });

  const activityTypesQuery = useQuery<ActivityTypes, QueryError>({
    queryKey: ["ActivityTypes"],
    queryFn: () => getActivityTypes(),
    staleTime: 600000,
    gcTime: 600000,
  });

  const error = activitiesQuery.error ?? activityTypesQuery.error ?? undefined;
  const isLoading =
    activitiesQuery.isLoading ||
    activitiesQuery.isFetching ||
    activityTypesQuery.isLoading ||
    activityTypesQuery.isFetching;

  const isEditable = React.useMemo(() => {
    return (
      isIncidentEditable(demand) &&
      canUserEditIncident(userData, demand._vdl_contactprincipal_value)
    );
  }, [demand, userData]);

  if (isLoading) return <p>LOADING</p>;

  if (error || !activitiesQuery.data || !activityTypesQuery.data)
    return <QueryErrorMessage error={error} />;

  return (
    <Card elevation={0} sx={{ bgcolor: "white", height: "100%" }}>
      <CardContent>
        <Box display="grid" rowGap="16px">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" fontWeight="bold" color="primary">
              Activités
            </Typography>
            {isEditable ? (
              <NewActivityButton incidentId={demand.incidentid} activityTypes={activityTypesQuery.data} />
            ) : null}
          </Box>
          <ActivityCards
            activites={activitiesQuery.data}
            activityTypes={activityTypesQuery.data}
            isEditable={isEditable}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActivitiesTab;
