import React, { useEffect, useState } from "react";
import {
  IncidentPatch,
  IncidentSpecialEventPatch,
  IncidentSpecialEventRow,
} from "../../../../../data/Incident/models";
import { NewDemandFields } from "../../../../../data/Incident/forms/dialog";
import EditDemandLayout from "../../EditDemandLayout";
import SimpleFields from "../../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../../hooks/useTabs";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralFields,
} from "../../../../../data/Incident/forms/specialEvents/specialEventsGeneral";
import {
  EditDemandSpecialEventFields,
  editDemandSpecialEventFields,
  editDemandSpecialEventSections,
} from "../../../../../data/Incident/forms/specialEvents/specialEvent/specialEvent";
import { validateDateString } from "../../../../../data/common/validateDateString";
import SpecialEvents from "../GeneralFields/SpecialEvents";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import {
  IncidentSpecialEventsDateEditing,
  IncidentSpecialEventsDatePatch,
} from "../../../../../data/Incident/types/specialEvents/dates";
import { buildForm } from "../../../../form/SimpleForm/buildForm";
import handleSimpleFormSrollToError from "../../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";

type Props = {
  demand: IncidentSpecialEventRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const SpecialEvent = ({ demand, generalFields, update }: Props) => {

  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(generalFields);

  const [specialEventFields, setSpecialEventFields] =
    useState<EditDemandSpecialEventsGeneralFields>(
      buildForm(editDemandSpecialEventsGeneralFields, {
        vdl_lieudelevenement: demand.vdl_detailsdelademande.vdl_lieudelevenement ?? "",
        vdl_precisionsurlelieu: demand.vdl_detailsdelademande.vdl_precisionsurlelieu,
        vdl_siautreprecisezlieu: demand.vdl_detailsdelademande.vdl_siautreprecisezlieu ?? "",
        vdl_encasdintemperieslevenementest:
          demand.vdl_detailsdelademande.vdl_encasdintemperieslevenementest ?? [],
        vdl_siremisle: validateDateString(demand.vdl_detailsdelademande.vdl_siremisle),
      })
    );

  const [fields, setFields] = useState<EditDemandSpecialEventFields>(
    buildForm(editDemandSpecialEventFields, {
      vdl_nomdelevenement: demand.vdl_detailsdelademande.vdl_nomdelevenement ?? "",
      vdl_naturedelevenement: demand.vdl_detailsdelademande.vdl_naturedelevenement ?? [],
      vdl_siautreprecisez: demand.vdl_detailsdelademande.vdl_siautreprecisez ?? "",
      vdl_descriptiondelevenement: demand.vdl_detailsdelademande.vdl_descriptiondelevenement ?? "",
      vdl_rayonnementdelevenement: demand.vdl_detailsdelademande.vdl_rayonnementdelevenement ?? [],
      vdl_nouvelledemande: demand.vdl_detailsdelademande.vdl_nouvelledemande ?? false,
      vdl_demanderecurrente: demand.vdl_detailsdelademande.vdl_demanderecurrente ?? false,
      vdl_sireccurentdatedeladerniereedition: validateDateString(demand.vdl_detailsdelademande.vdl_sireccurentdatedeladerniereedition),
      vdl_nombredeparticipantsetdevisiteursattendus: demand.vdl_detailsdelademande.vdl_nombredeparticipantsetdevisiteursattendus,
      vdl_clienteleviseeparlevenement:
        demand.vdl_detailsdelademande.vdl_clienteleviseeparlevenement ?? [],
      vdl_sispecifiqueprecisez: demand.vdl_detailsdelademande.vdl_sispecifiqueprecisez ?? "",
      vdl_engagementloisetreglement:
        demand.vdl_detailsdelademande.vdl_engagementloisetreglement ?? false,
      vdl_engagementequiteetinclusion:
        demand.vdl_detailsdelademande.vdl_engagementequiteetinclusion ?? false,
      vdl_engagementdeveloppementdurable:
        demand.vdl_detailsdelademande.vdl_engagementdeveloppementdurable ?? false,
      vdl_responsabilitedumaterielprete:
        demand.vdl_detailsdelademande.vdl_responsabilitedumaterielprete ?? false,
      vdl_engagementhabitudessaines:
        demand.vdl_detailsdelademande.vdl_engagementhabitudessaines ?? false,
    })
  );
  const [dates, setDates] = useState<IncidentSpecialEventsDateEditing[]>(
    demand.vdl_detailsdelademande.dates
  );
  const { activeTab, changeTab } = useTabs();
  const { showSnackbar } = useSnackbar();

  const onSubmit = async () => {
    const errors = [...handleSimpleFormSubmit(fields, setFields), ...handleSimpleFormSubmit(specialEventFields, setSpecialEventFields)];
    
    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const _demand: IncidentSpecialEventPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_lieudelevenement: specialEventFields.vdl_lieudelevenement.value,
        vdl_precisionsurlelieu: specialEventFields.vdl_precisionsurlelieu.value,
        vdl_siautreprecisezlieu:
          specialEventFields.vdl_siautreprecisezlieu.value,
        vdl_encasdintemperieslevenementest:
          specialEventFields.vdl_encasdintemperieslevenementest.value,
        vdl_siremisle: specialEventFields.vdl_siremisle.value?.toISOString(),

        vdl_nomdelevenement: fields.vdl_nomdelevenement.value,
        vdl_naturedelevenement: fields.vdl_naturedelevenement.value,
        vdl_siautreprecisez: fields.vdl_siautreprecisez.value,
        vdl_descriptiondelevenement: fields.vdl_descriptiondelevenement.value,
        vdl_rayonnementdelevenement: fields.vdl_rayonnementdelevenement.value,
        vdl_nouvelledemande: fields.vdl_nouvelledemande.value,
        vdl_demanderecurrente: fields.vdl_demanderecurrente.value,

        vdl_sireccurentdatedeladerniereedition:
          fields.vdl_sireccurentdatedeladerniereedition.value?.toISOString(),

        dates: [],

        vdl_nombredeparticipantsetdevisiteursattendus:
          fields.vdl_nombredeparticipantsetdevisiteursattendus.value,
        vdl_clienteleviseeparlevenement:
          fields.vdl_clienteleviseeparlevenement.value,
        vdl_sispecifiqueprecisez: fields.vdl_sispecifiqueprecisez.value,

        vdl_engagementloisetreglement:
          fields.vdl_engagementloisetreglement.value,
        vdl_engagementequiteetinclusion:
          fields.vdl_engagementequiteetinclusion.value,
        vdl_engagementdeveloppementdurable:
          fields.vdl_engagementdeveloppementdurable.value,
        vdl_responsabilitedumaterielprete:
          fields.vdl_responsabilitedumaterielprete.value,
        vdl_engagementhabitudessaines:
          fields.vdl_engagementhabitudessaines.value,
      },
    };

    const datesUpdate: IncidentSpecialEventsDatePatch[] = [];

    let validDatesCount = 0;

    dates.forEach((date) => {
      if ("vdl_datesid" in date) {
        if ("delete" in date) {
          datesUpdate.push({
            vdl_datesid: date.vdl_datesid,
            delete: true,
          });
        } else {
          validDatesCount++;
        }
      } else {
        validDatesCount++;
        datesUpdate.push({
          ...date,
        });
      }
    });

    if (validDatesCount === 0) {
      showSnackbar(
        "Veuillez entrer au moins une date avant d'enregistrer la demande",
        "error"
      );
      return;
    }

    _demand.vdl_detailsdelademande.dates = datesUpdate;

    await update(_demand);
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
    >
      <SpecialEvents
        fields={specialEventFields}
        setFields={setSpecialEventFields}
        dates={dates}
        setDates={setDates}
      />
      <SimpleFields
        fields={fields}
        setFields={setFields}
        sections={editDemandSpecialEventSections}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default SpecialEvent;
