import { ErrorType, OperationType, QueryError } from "../../types";
import fetchMsal from "../fetchMsal";
import { ActivityPatch, ActivityPost, ActivityRow } from "./models";

export const getActivities = async (incidentid: string): Promise<ActivityRow[]> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_activities/${incidentid}`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Activité",
      OperationType.RETRIEVEMULTIPLE,
      incidentid
    );
  }
};

export const createActivity = async (
  activity: ActivityPost
): Promise<string | undefined> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/create_activity/`;
    const result = await fetchMsal("POST", endpoint, activity);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

    return await result.text();
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Activité", OperationType.CREATE);
  }
};

export const updateActivity = async (
  activityId: string,
  activity: ActivityPatch
): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_activity/${activityId}`;
    const result = await fetchMsal("POST", endpoint, activity);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Activité", OperationType.UPDATE);
  }
};
