import { Dialog, Box, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactRow } from "../../../../data/Contact/models";
import { newDemandWithTypeFields } from "../../../../data/Incident/forms/dialog";
import {
  IncidentFilmingRow,
  IncidentParentRow,
  IncidentPost,
  IncidentSpecialEventRow,
} from "../../../../data/Incident/models";
import { createIncident } from "../../../../data/Incident/queries";
import { ServiceRow } from "../../../../data/Service/models";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import useUser from "../../../../hooks/useUserData";
import { RouterRoutes } from "../../../../routes/routes";
import { FormFields, FormFieldsBuilder, QueryError } from "../../../../types";
import Transition from "../../../common/animation/DialogTransition";
import SimpleFormWithSectionsWithState from "../../../form/SimpleForm/SimpleFormWithSectionsWithState";
import { buildPartialFormObj } from "../../../form/SimpleForm/buildForm";
import { ProgramRow } from "../../../../data/Program/models";
import {
  NewActivityFields,
  newActivityFields,
} from "../../../../data/Activity/forms/dialog";
import { ActivityTypes } from "../../../../data/ActivityType/models";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activityTypes: ActivityTypes;
  openEditActivityForm: (typeId: string) => void;
};

const NewActivityDialog = ({ open, setOpen, activityTypes, openEditActivityForm }: Props) => {
  const { userData } = useUser();
  const queryClient = useQueryClient();

  function onCategorieChange(
    _fields: NewActivityFields
  ): Partial<NewActivityFields> | undefined {
    try {
      if (_fields.categorie.value === null)
        return {
          _vdl_typedactivite_value: {
            ..._fields._vdl_typedactivite_value,
            value: null,
            choices: Object.entries(activityTypes).map(([key, value]) => {
              return {
                value: key,
                label: value.vdl_typedactivite,
              };
            }),
          },
        };
      return {
        _vdl_typedactivite_value: {
          ..._fields._vdl_typedactivite_value,
          value: null,
          choices: Object.entries(activityTypes)
            .filter(
              ([key, value]) => value.vdl_categorie === _fields.categorie.value
            )
            .map(([key, value]) => {
              return {
                value: key,
                label: value.vdl_typedactivite,
              };
            }),
        },
      };
    } catch (e) {
      return undefined;
    }
  }
  function onTypeActiviteChange(
    _fields: NewActivityFields
  ): Partial<NewActivityFields> | undefined {
    try {
      if (_fields._vdl_typedactivite_value.value !== null) {
        const type = activityTypes[_fields._vdl_typedactivite_value.value];
       
        if (type !== undefined) {
          return {
            _vdl_typedactivite_value: {
              ..._fields._vdl_typedactivite_value,
              helperText: type.vdl_description,
            },
          };
        }
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  }

  const [fields, setFields] = useState(
    buildPartialFormObj(newActivityFields, {
      categorie: {
        value: null,
        onChangeEvent: (fieldsOnChange) =>
          onCategorieChange(fieldsOnChange as NewActivityFields),
      },
      _vdl_typedactivite_value: {
        value: null,
        onChangeEvent: (fieldsOnChange) =>
          onTypeActiviteChange(fieldsOnChange as NewActivityFields),
        choices: Object.entries(activityTypes).map(([key, value]) => {
          return {
            value: key,
            label: value.vdl_typedactivite,
          };
        }),
      },
    })
  );


  const { showSnackbar, showErrorSnackbar } = useSnackbar();

  async function handleSubmit() {
    if (!fields._vdl_typedactivite_value.value) return;

    openEditActivityForm(fields._vdl_typedactivite_value.value);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      fullWidth
    >
      <Box padding="16px 16px 0 16px">
        <Typography variant="h5" color="primary">
          Créer une nouvelle activité pour l'évènement
        </Typography>
        <Typography>
          Après l'initiation de la création de l'activité, vous serez redirigé
          vers le formulaire de détail de l'activité pour compléter les
          informations spécifiques
        </Typography>
      </Box>
      <SimpleFormWithSectionsWithState
        fields={fields}
        setFields={setFields}
        columns={{ minWidth: 220, count: 1 }}
        submitText={"Initier la création"}
        handleSuccess={handleSubmit}
        cancelText="Annuler"
        handleCancel={() => setOpen(false)}
      />
    </Dialog>
  );
};

export default NewActivityDialog;
