import React, { useState } from "react";
import EditDemandLayout from "../EditDemandLayout";
import {
  IncidentFundraiserPatch,
  IncidentFundraiserRow,
  IncidentPatch,
} from "../../../../data/Incident/models";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";

import {
  EditDemandFundraiserFields,
  editDemandFundraiser,
  editDemandFundraiserSections,
} from "../../../../data/Incident/forms/fundraiser";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import {buildForm} from "../../../form/SimpleForm/buildForm";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";

type Props = {
  demand: IncidentFundraiserRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>
};

const Fundraiser = ({
  demand,
  generalFields,
  update
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  
  const [fieldsGeneral, setFieldsGeneral] =
  useState<NewDemandFields>(generalFields);

  const [fields, setFields] = useState<EditDemandFundraiserFields>(
    buildForm(editDemandFundraiser, {
      vdl_projetdefinancement: demand.vdl_detailsdelademande.vdl_projetdefinancement ?? "",
      vdl_stmartinboisdeboulogne: demand.vdl_detailsdelademande.vdl_stmartinboisdeboulogne,
      vdl_jeanberauddanieljohnson: demand.vdl_detailsdelademande.vdl_jeanberauddanieljohnson,
      vdl_chomedeycartier: demand.vdl_detailsdelademande.vdl_chomedeycartier,
      vdl_notredamelavatere: demand.vdl_detailsdelademande.vdl_notredamelavatere,
      vdl_nombredejeunesparticipants18ansetmoins:
        demand.vdl_detailsdelademande.vdl_nombredejeunesparticipants18ansetmoins,
      vdl_nombredadultesparticipants5min:
        demand.vdl_detailsdelademande.vdl_nombredadultesparticipants5min,
      vdl_erjour: validateDateString(demand.vdl_detailsdelademande.vdl_erjour),
      vdl_emejour: validateDateString(demand.vdl_detailsdelademande.vdl_emejour),
      vdl_3emejour: validateDateString(demand.vdl_detailsdelademande.vdl_3emejour),
      vdl_4emejour: validateDateString(demand.vdl_detailsdelademande.vdl_4emejour),
      vdl_5emejour: validateDateString(demand.vdl_detailsdelademande.vdl_5emejour),
      vdl_engagementaurespectdesproceduresetdirectives:
        demand.vdl_detailsdelademande.vdl_engagementaurespectdesproceduresetdirectives ?? false,
      vdl_jemengageasuivrelaformationobligatoire:
        demand.vdl_detailsdelademande.vdl_jemengageasuivrelaformationobligatoire ?? false,
      vdl_jaidejasuivilaformationobligatoireannee: validateDateString(
        demand.vdl_detailsdelademande.vdl_jaidejasuivilaformationobligatoireannee
      ),
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }


    const _demand: IncidentFundraiserPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_projetdefinancement: fields.vdl_projetdefinancement.value,
        vdl_jeanberauddanieljohnson: fields.vdl_jeanberauddanieljohnson.value,
        vdl_chomedeycartier: fields.vdl_chomedeycartier.value,
        vdl_stmartinboisdeboulogne: fields.vdl_stmartinboisdeboulogne.value,
        vdl_notredamelavatere: fields.vdl_notredamelavatere.value,
        vdl_nombredejeunesparticipants18ansetmoins:
          fields.vdl_nombredejeunesparticipants18ansetmoins.value,
        vdl_nombredadultesparticipants5min:
          fields.vdl_nombredadultesparticipants5min.value,
        vdl_erjour: fields.vdl_erjour.value?.toString(),
        vdl_emejour: fields.vdl_emejour.value?.toString(),
        vdl_3emejour: fields.vdl_3emejour.value?.toString(),
        vdl_4emejour: fields.vdl_4emejour.value?.toString(),
        vdl_5emejour: fields.vdl_5emejour.value?.toString(),
        vdl_engagementaurespectdesproceduresetdirectives:
          fields.vdl_engagementaurespectdesproceduresetdirectives.value,
        vdl_jemengageasuivrelaformationobligatoire:
          fields.vdl_jemengageasuivrelaformationobligatoire.value,
        vdl_jaidejasuivilaformationobligatoireannee:
          fields.vdl_jaidejasuivilaformationobligatoireannee.value?.toString(),
      },
    };

     await update(_demand)
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        columns={{ count: 2, minWidth: 290 }}
        sections={editDemandFundraiserSections}
        sectionsBackground="white"
      />
    </EditDemandLayout>
  );
};

export default Fundraiser;
