import React, { useState } from "react";
import NewActivityDialog from "./NewActivityDialog";
import CustomButton from "../../../common/buttons/CustomButton";
import {
  IncidentSpecialEventRow,
  IncidentFilmingRow,
} from "../../../../data/Incident/models";
import {
  ActivityTypeRow,
  ActivityTypes,
} from "../../../../data/ActivityType/models";
import ActivityFormDialog from "./ActivityFormDialog";

type Props = {
  incidentId: string;
  activityTypes: ActivityTypes;
};

const NewActivityButton = ({ incidentId, activityTypes }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newActivityType, setNewActivityType] =
    useState<ActivityTypeRow | null>(null);

  function openEditActivityForm(typeId: string) {
    const type = activityTypes[typeId];
    if (!type) return;

    setNewActivityType(type);
    setIsDialogOpen(false);
  }

  function handleCloseForm() {
    setNewActivityType(null);
  }

  return (
    <>
      <CustomButton
        primary
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        Ajouter
      </CustomButton>
      <NewActivityDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        activityTypes={activityTypes}
        openEditActivityForm={openEditActivityForm}
      />
      {newActivityType ? (
        <ActivityFormDialog
          incidentId={incidentId}
          activityRow={undefined}
          activityType={newActivityType}
          handleCloseForm={handleCloseForm}
        />
      ) : null}
    </>
  );
};

export default NewActivityButton;
