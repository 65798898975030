import React, { useState } from "react";
import BoxResponsivePadding from "../../../common/boxes/BoxResponsivePadding";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import {
  EditDemandInfoRequest,
  editDemandInfoRequestFields,
} from "../../../../data/Incident/forms/infoRequest";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";
import EditDemandLayout from "../EditDemandLayout";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { buildForm } from "../../../form/SimpleForm/buildForm";
import {
  IncidentInfoRequestPatch,
  IncidentInfoRequestRow,
  IncidentPatch,
} from "../../../../data/Incident/models";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";

type Props = {
  demand: IncidentInfoRequestRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>
};

const InfoRequest = ({
  demand,
  generalFields,
  update
}: Props) => {

  const [fieldsGeneral, setFieldsGeneral] =
  useState<NewDemandFields>(generalFields);

  const [fields, setFields] = useState<EditDemandInfoRequest>(
    buildForm(editDemandInfoRequestFields, {
      vdl_sujetdelademande: demand.vdl_detailsdelademande.vdl_sujetdelademande ?? "",
      vdl_description: demand.vdl_detailsdelademande.vdl_description ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const _demand: IncidentInfoRequestPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_sujetdelademande: fields.vdl_sujetdelademande.value,
        vdl_description: fields.vdl_description.value,
      },
    };

    await update(_demand)
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
    >
      <BoxResponsivePadding bgcolor="common.white">
        <SimpleFields
          updateRefs
          fields={fields}
          setFields={setFields}
          title={demand._vdl_typededemande_value_FormattedValue}
        />
      </BoxResponsivePadding>
    </EditDemandLayout>
  );
};

export default InfoRequest;
